import React, { useState, Suspense, useEffect } from "react";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loader from "./pages/loader.js";
import { LoaderContext } from "./contex/loader.js";
import { useContext } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
const Module = React.lazy(() => import("./pages/module"));
const Parametre = React.lazy(() => import("./pages/parametre"));
const Message = React.lazy(() => import("./pages/message"));
const Article = React.lazy(() => import("./pages/article.js"));
const Articles = React.lazy(() => import("./pages/Articles.js"));
const Proverbe = React.lazy(() => import("./pages/proverbes.js"));
const Jardin = React.lazy(() => import("./pages/Jardin.js"));
const ManuelT = React.lazy(() => import("./pages/manuelT.js"));
const Membre = React.lazy(() => import("./pages/membre.js"));
const Forum = React.lazy(() => import("./pages/forum.js"));
const Gallery = React.lazy(() => import("./pages/gallery.js"));
const Donate = React.lazy(() => import("./pages/donate.js"));
const Dashboard = React.lazy(() => import("./pages/Dashboard.js"));
const Team = React.lazy(() => import("./pages/Team"));
const TeamBoardMembers = React.lazy(() => import("./pages/TeamBoardMembers"));
const TeamExecutive = React.lazy(() => import("./pages/TeamExecutive"));
const TeamAlumni = React.lazy(() => import("./pages/TeamAlumni"));
const Mission = React.lazy(() => import("./pages/Mission"));
const Vision = React.lazy(() => import("./pages/Vision"));
const Value = React.lazy(() => import("./pages/Value"));
const Contact = React.lazy(() => import("./pages/ContactUs"));
const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));
const Ebai = React.lazy(() => import("./pages/Ebai"));
const Document = React.lazy(() => import("./pages/document"));
const Teams = React.lazy(() => import("./pages/Teams"));
const Seminaire = React.lazy(() => import("./pages/Seminaire"));
const Dec = React.lazy(() => import("./pages/Dec"));
const Home = React.lazy(() => import("./pages/Home"));
const Executive = React.lazy(() => import("./pages/TeamExecutive.js"));

function App() {
  const { toggleLoader } = useContext(LoaderContext);
  toggleLoader(true);

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/board_members" element={<TeamBoardMembers />} />
          <Route exact path="/team-executive" element={<TeamExecutive />} />
          <Route exact path="/team-alumni" element={<TeamAlumni />} />
          <Route exact path="/mission" element={<Mission />} />
          <Route exact path="/vision" element={<Vision />} />
          <Route exact path="/value" element={<Value />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/ebai" element={<Ebai />} />
          <Route exact path="/document" element={<Document />} />
          <Route exact path="/teams" element={<Teams />} />
          <Route exact path="/seminaire" element={<Seminaire />} />
          <Route exact path="/dec" element={<Dec />} />
          <Route exact path="/manuel/:id" element={<Module />} />
          <Route exact path="/parametre" element={<Parametre />} />
          <Route exact path="/message" element={<Message />} />
          <Route exact path="/article" element={<Article />} />
          <Route exact path="/articles" element={<Articles />} />
          <Route exact path="/proverbe" element={<Proverbe />} />
          <Route exact path="/jardin" element={<Jardin />} />
          <Route exact path="/manuelT" element={<ManuelT />} />
          <Route exact path="/membre" element={<Membre />} />
          <Route exact path="/forum" element={<Forum />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/donate" element={<Donate />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/executive" element={<Executive />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
