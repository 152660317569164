import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LangueProvider } from "./contex/langue.js";
import { LoaderProvider } from "./contex/loader.js";
import { UserProvider } from "./contex/user.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

function AppWithErrorBoundary() {
  return (
    <React.StrictMode>
      <UserProvider>
        <LoaderProvider>
          <LangueProvider>
            <App />
          </LangueProvider>
        </LoaderProvider>
      </UserProvider>
    </React.StrictMode>
  );
}

root.render(<AppWithErrorBoundary />);

reportWebVitals();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
