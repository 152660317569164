import React, { createContext, useState } from "react";

export const LangueContext = createContext();

export const LangueProvider = ({ children }) => {
  const [langue, setLangue] = useState(() => {
    const langues = localStorage.getItem("selectedLanguage");
    return langues ? langues : "en";
  });

  const toggleLangue = () => {
    const newLangue = langue === "fr" ? "en" : "fr";
    localStorage.setItem("selectedLanguage", newLangue);
    window.location.reload(false);
  };

  return (
    <LangueContext.Provider value={{ langue, toggleLangue }}>
      {children}
    </LangueContext.Provider>
  );
};
