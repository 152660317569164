import React, { useContext } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LoaderContext } from "../contex/loader.js";
import ReactLoading from "react-loading";

const Loader = () => {
  const { loader } = useContext(LoaderContext);

  return (
    <Backdrop
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 1)", // Vous pouvez ajuster l'opacité ici
        zIndex: (theme) => theme.zIndex.drawer + 1,
        pointerEvents: "none", // Empêche les événements pointer d'être reçus par les éléments sous-jacents
      }}
      open={loader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
